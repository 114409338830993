import * as React from 'react';
import { PageProps } from 'gatsby';
import Button, { ButtonProps } from "@/components/Button/button"
import Section from '@/components/Section/Section';
import Col from '@/components/Col/Col';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import Title from '@/components/Title/Title';
import Counter from '@/components/Counter/Counter';
import ios14 from "@/img/bg/bg_ios14.png"
import benefit1 from "@/img/ico/icoBenefit1.svg"
import benefit2 from "@/img/ico/icoBenefit2.svg"
import benefit3 from "@/img/ico/icoBenefit3.svg"
import benefit4 from "@/img/ico/icoBenefit4.svg"
import noLimit from "@/img/ico/noLimit.svg"
import noTurnOff from "@/img/ico/noTurnOff.svg"
import noRely from "@/img/ico/noRely.svg"
import noAttribute from "@/img/ico/noAttribute.svg"
import ecomtrackNotebook from "@/img/ecomtrackNotebook.jpg"


const WhyEcomtrack = () => (
    <main>
      <Header isWhyEcomtrack></Header>

      <Section colFlex vCentered className="hero">
          <Col size="50" className="hero__headline">
            <h1>96% of iPhone users opted to stay out of tracking.</h1>
              <p>
                After Apple updated the iOS 14.5 people can now choose if they want to be tracked
                by apps, such as Facebook or Instagram. So then Facebook’s pixel cannot track with accuracy
                majority of people clicking on your ads.
              </p>
              <p>

              </p>
              <div className="testimonial__logos__buttons">
                <Button
                {...{
                  variant: "blue",
                  text: "How to fix it",
                  scrollTarget: "#consequences",
                }}
                />
              </div>
            </Col>

          <Col size="50" className="">
            <img src={ios14} />
          </Col>
      </Section>

      <Section colFlex id="consequences" className="consequences">
        <Col size="50">
          <div className="consequences__sticky">
            <h1>Consequences of&nbsp;<span className="violet">iOS&nbsp;14.5</span></h1>
          </div>
        </Col>
        <Col size="50">
          <div className="consequences__item" data-sal="slide-up" data-sal-duration="550" data-sal-delay="500" data-sal-easing="ease-out">
            <p className="consequences__item__no">01</p>
            <h3>Attribution window reduced by 75%</h3>
            <p>
              To make matters worse, Facebook has drastically changed its attribution model and cut down its
              reporting window by 75% – from 28 to only 7 days. Meaning any purchase that happens after 7 days
              from the first ad visit, no longer won’t be attributed to the ads that brought the person in the
              first place, resulting in missing multiple purchases that are happening after day 7.
            </p>
          </div>

          <div className="consequences__item" data-sal="slide-up" data-sal-duration="550" data-sal-delay="500" data-sal-easing="ease-out">
            <p className="consequences__item__no">02</p>
            <h3>72 hours delayed reporting</h3>
            <p>
              It can take Facebook up to 72 hours to report back on how your campaigns are performing. The question is,
              how can we make informed decisions where to invest the advertising budget if the data can take 3 days to
              get to us?
            </p>
          </div>

          <div className="consequences__item" data-sal="slide-up" data-sal-duration="550" data-sal-delay="500" data-sal-easing="ease-out" >
            <p className="consequences__item__no">03</p>
            <h3>Facebook cannot accurately track 96% of iPhone users</h3>
            <p>
              This leads to massive misattribution. As Facebook admits: „Results may not include conversions from people
              who opt out of tracking on iOS 14.5+. Statistical modelling may be used to help us estimate some conversions
              that we were not able to observe directly due to these opt-outs.“
            </p>
          </div>

          <div className="consequences__item" data-sal="slide-up" data-sal-duration="550" data-sal-delay="500" data-sal-easing="ease-out" >
            <p className="consequences__item__no">04</p>
            <h3>Inability of any business investing in ads to</h3>
            <p className="bullet">evaluate results</p>
            <p className="bullet">optimise ads</p>
            <p className="bullet">scale with certainty</p>
          </div>
        </Col>
      </Section>

      <Section className="loose">

        <Col size="70" className="loose__text">
          <div className="loose__title">
            <h3>New way to track and scale ads. Stop losing money right now!</h3>
          </div>

          <div className="loose__row">
            <div>
              <img className="loose__row__ico" src={noTurnOff} />
              <p>Turn off quickly the ads that are not generating the best results.</p>
            </div>
            <div>
              <img className="loose__row__ico" src={noLimit} />
              <p>Scale with certainty the profitable campaigns.</p>
            </div>
          </div>

          <div className="loose__row">
            <div>
              <img className="loose__row__ico" src={noRely} />
              <p>Make decisions based on accurate data, no more guessing.</p>
            </div>
            <div>
              <img className="loose__row__ico" src={noAttribute} />
              <p>Don't be limited by the 7-day attribution window.</p>
            </div>
          </div>

          <div className="loose__buttons">
            <Button
              {...{
                variant: "blue",
                link: "https://app.ecomtrack.io/register",
                text: "Start tracking",
              }}
              />

              <Button
                {...{
                  variant: "transparent",
                  link: "/solution",
                  text: "Know more",
                }}
                />

          </div>

        </Col>

        <Col size="30" className="loose__bg">

        </Col>

      </Section>

      <Section className="counter">
        <h6>High credibility</h6>
        <h1>We know what we are doing. After 3 years on market cooperating with many clients we have spent
        {/*<Counter objectId="counter__number" start="1" end="50" duration="2000" />*/}
        <span id="counter__number"> $</span>
        <div className="ulWrap ulWrapDecimal">
          <ul class="content__container__list">
            <li class="content__container__list__item">0</li>
            <li class="content__container__list__item">1</li>
            <li class="content__container__list__item">2</li>
            <li class="content__container__list__item">3</li>
            <li class="content__container__list__item">4</li>
            <li class="content__container__list__item">5</li>
          </ul>
        </div>
        <div className="ulWrap">
          <ul class="content__container__list">

            <li class="content__container__list__item">1</li>
            <li class="content__container__list__item">2</li>
            <li class="content__container__list__item">3</li>
            <li class="content__container__list__item">4</li>
            <li class="content__container__list__item">5</li>
            <li class="content__container__list__item">6</li>
            <li class="content__container__list__item">7</li>
            <li class="content__container__list__item">8</li>
            <li class="content__container__list__item">9</li>
            <li class="content__container__list__item">0</li>
          </ul>
        </div>
        <span id="counter__number">,000,000+ </span>
         on social media ads.</h1>
      </Section>

      <Section className="benefits">

        <div className="benefits__title">
          <h1>Benefits of ecomtrack</h1>
          <Button
            {...{
              variant: "blue",
              link: "/solution",
              text: "How it works",
            }}
            />
        </div>

          <div className="benefits__row">

            <div className="benefits__row__item">

              <img src={benefit2} />
              <h3>Attribute 100% of your sales to the correct ad</h3>
              <p>Say goodbye to Facebook’s misattribution, over-reporting, or under-reporting with our server to server connection.</p>

            </div>

            <div className="benefits__row__item">

              <img src={benefit1} />
              <h3>Reduce wasted budget</h3>
              <p>With accurate data, you can finally scale the performing ads with confidence and turn off any ads that are not profitable. As a result, ecomtrack’s clients grow their businesses faster and more profitably.</p>

            </div>

          </div>

          <div className="benefits__row">

            <div className="benefits__row__item">

              <img src={benefit4} />
              <h3>Customisable attribution models</h3>
              <p>With us you have freedom to choose the attribution model that suits your business the most. From 7-day, 14-day, 28-day attribution window to „last click“ attribution model.</p>

            </div>

            <div className="benefits__row__item">

              <img src={benefit3} />
              <h3>Easy & fast integration</h3>
              <p>ecomtrack offers a seamless integration with Shopify, WooCommerce, or we can custom-integrate any platform your business uses.</p>

            </div>

          </div>

          <div className="benefits__end">
              <h3>Gain an edge over your competitors</h3>
              <p>Most businesses continue using inaccurate Facebook Ads Manager data, so using ecomtrack gives you advantage over them all. </p>
          </div>

      </Section>

      <Section className="overview">

        <Title
          maintitle="Based on our stats"
          subtitle="Situation"
        ></Title>

        <div className="overview__wrap">

          <Col size="50">
            <h4>20-50% misattributed</h4>
            <p>
              On average 20-50% (depending on client’ size & industry) of ads were misattributed
              by Facebook - either results were attributed to the wrong campaign, ad set, ad, or
              numbers were over-reported or under-reported.
            </p>

            <h4>Average 25% ROAS increase</h4>
            <p>
              After businesses have accurate data, they turn off quicker underperforming ads and begin
              putting budget behind „winning ads“. As a result, there has been on average 25% increase
              in return on ad spend after 30 days of using ecomtrack.
            </p>

          </Col>

          <Col size="50">
            <img src={ecomtrackNotebook} />
          </Col>

        </div>

        <div className="prizing__ready">
          <Col size="50">
            <h3>Ready to start tracking?</h3>
          </Col>
          <Col size="50">
          <Button
          {...{
            variant: "blue",
            link: "https://app.ecomtrack.io/register",
            text: "Start tracking",
          }}
          />
          </Col>
        </div>

      </Section>

      <Footer colFlex>
      </Footer>

    </main>

);
export default WhyEcomtrack;
